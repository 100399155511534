import ky, { SearchParamsOption } from 'ky';
import { StringUtil } from './string-util';

export const getRequest = async (
  url: string,
  searchParams?: SearchParamsOption,
  responseType?: 'blob',
  timeout?: number,
) => {
  const apiClient = ky.create({
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      responseType: responseType,
    },
    timeout: timeout || 10000,
  });

  const response = await apiClient.get(url, { searchParams });
  return response;
};

export const postRequest = async (url: string, params: Object) => {
  const apiClient = ky.create({
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
    timeout: 10000,
  });
  const requestParams = StringUtil.camelObjectToSnakeObject(params);

  return await apiClient.post(url, { json: requestParams });
};

export const patchRequest = async (url: string, params: Object) => {
  const apiClient = ky.create({
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
    timeout: 10000,
  });
  const requestParams = StringUtil.camelObjectToSnakeObject(params);

  return await apiClient.patch(url, { json: requestParams });
};

export const deleteRequest = async (url: string) => {
  const apiClient = ky.create({
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
    timeout: 10000,
  });

  return await apiClient.delete(url);
};
